.formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;

  span {
    color:var(--color-black);
    font-weight: 400;
  }

  input {
    padding: 12px 16px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid var(--color-second);
  }



  &__container {
    width: 100%;
    position: relative;
    &__child {
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
    }
  }

}
