.container {
  &__filter {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;

    & > div {
      display: flex;
      align-items: center;
      gap: 32px;
    }

    label {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border-radius: 8px;
      gap: 8px;
      background: var(--background-blue);
      white-space: nowrap;
    }
  }
}

