.layout {
  &__content {
    height: 100vh;
    width: 100%;
    overflow-y: auto;
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
}
