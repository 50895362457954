.aside {
  width: 100%;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 12px;

  &__title {
    font-size: 16px;
    padding: 8px 16px;
    font-weight: bold;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;

    &__text {
      padding: 8px 16px;
      color: var(--color-second);
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;

      &__active {
        background: var(--linear-gradient-blue-red);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }
}
