.navLink {
  padding: 10px 14px;
  width: 100%;
  height: 40px;
  display: flex;
  border-radius: 200px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  position: relative;

  &__leftPart {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__rightPart {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    color: var(--color-second);
    justify-content: space-between;
    &__text {
      font-weight: 600;
    }
  }
}

.active {
  background: var(--linear-gradient-blue-red);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

