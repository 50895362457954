@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/MontserratFont/Montserrat-Regular.woff2") format("woff2"),
  url("../assets/fonts/MontserratFont/Montserrat-Regular.woff") format("woff"),
  url("../assets/fonts/MontserratFont/Montserrat-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/MontserratFont/Montserrat-Medium.woff2") format("woff2"),
  url("../assets/fonts/MontserratFont/Montserrat-Medium.woff") format("woff"),
  url("../assets/fonts/MontserratFont/Montserrat-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/MontserratFont/Montserrat-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/MontserratFont/Montserrat-SemiBold.woff") format("woff"),
  url("../assets/fonts/MontserratFont/Montserrat-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/MontserratFont/Montserrat-Bold.woff2") format("woff2"),
  url("../assets/fonts/MontserratFont/Montserrat-Bold.woff") format("woff"),
  url("../assets/fonts/MontserratFont/Montserrat-Bold.ttf") format("opentype");
}


@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/InterFont/Inter-Regular.woff2") format("woff2"),
  url("../assets/fonts/InterFont/Inter-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/InterFont/Inter-Medium.woff2") format("woff2"),
  url("../assets/fonts/InterFont/Inter-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/InterFont/Inter-SemiBold.woff2") format("woff2"),
  url("../assets/fonts/InterFont/Inter-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/InterFont/Inter-Bold.woff2") format("woff2"),
  url("../assets/fonts/InterFont/Inter-Bold.ttf") format("opentype");
}
