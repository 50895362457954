.tablePagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
  .content {
    width: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    button {
      position: static;
      width: 30px;
      height: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      flex: none;
      order: 0;
      background: var(--background-blue);
      border-radius: 100px;
      flex-grow: 0;
      margin: 0px 12px;

      &.active {
        color:var(--background-main);
        background: var(--linear-gradient-blue-red);
      }
    }

    .rightArrow {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      .icon {
        transform: rotate(-180deg);
      }
    }

    .leftArrow {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
    }
  }
}
