.row {
  display: grid;
  min-height: 60px;
  padding: 10px 10px;
  align-items: center;
  position: relative;
  z-index: 1;
  overflow: visible;
}

.underlined {
  width: 100%;
  border-bottom: 1px solid rgb(226, 238, 247);
}

.usuallyRow {
  cursor: pointer;
  min-width: min-content;
  width: 100%;

  &:hover {
    background: var(--background-blue);
    border-radius: 16px;
  }
}


.titleContainer {
  width: 100%;
  height: inherit;
}

.hoverContent {
  z-index: 999;
}
