.title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}

.container {
  width: 100%;
  height: 100%;

  &__content {
    display: grid;
    width: 100%;
    grid-template-columns: 500px 1fr;
    gap: 40px;

    @media(max-width: 960px) {
      grid-template-columns: 1fr;
    }

    &__leftPart {
      width: 100%;
      display: flex;
      align-self: start;
      flex-direction: column;
      justify-content: center;
      gap: 40px;

      &__inputs {
        display: flex;
        gap:16px;
        flex-direction: column;
      }

      &__buttons {
        justify-content: center;
        display: flex;
        gap: 24px;

        @media(max-width: 960px) {
          flex-direction: column;
        }

        span {
          color: #FF735D;
        }
      }
    }

    &__rightPart {

      &__products {
        min-height: 580px;
        max-width: 100%;
        overflow-x: auto;

        &_table {
          @media(max-width: 900px) {
            width: 800px;
          }
        }
      }

      .hoverItem{
        background: var(--linear-gradient-blue-red);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
    }
  }
}
