.footerProfile {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgb(228, 227, 228);
  border-top: 1px solid rgb(228, 227, 228);
  background: rgb(242, 251, 254);
  padding: 10px 20px ;

  &_leftPart {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    gap: 4px;
    &_email {
      background: linear-gradient(90.00deg, rgb(115, 73, 158),rgb(248, 69, 96));
      -webkit-background-clip:
              text;
      -webkit-text-fill-color:
              transparent;
      background-clip:
              text;
      text-fill-color:
              transparent;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0%;
      text-align: left;
    }
    &_balance {
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0%;
      text-align: left;
    }
  }

  &_rightPart {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    &_exit {
      background: linear-gradient(90.00deg, rgb(115, 73, 158),rgb(248, 69, 96));
      -webkit-background-clip:
              text;
      -webkit-text-fill-color:
              transparent;
      background-clip:
              text;
      text-fill-color:
              transparent;
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: -1%;
      text-align: left;
    }
  }
}