.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  height: 100%;
  width: 100%;

  &__content {
    width: 400px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media(max-width: 485px) {
      width: 100%;
    }


    &__inputs {
      width: 100%;
      gap: 20px;
      display: flex;
      flex-direction: column;

    }

    &__buttons {
      gap: 20px;
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }
}
