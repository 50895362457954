.button {
  padding: 8px 16px;
  display: flex;
  width: 100%;
  height: 42px;
  flex-direction: row;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  align-items: center;
  min-width: 140px;
  border-radius: 8px;

  &__contained {
    width: 100%;
    background: var(--linear-gradient-blue-red);
    color: var(--background-main)
  }

  &__outlined {
    width: 100%;
    background: transparent;
    border: none;
  }

  &__contained,
  &__outlined {
    flex: 1;
  }
}
