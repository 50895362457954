.column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--color-black);
    position: relative;

    & > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        p {
            white-space: pre-wrap;
            display: table;
            text-align: left;
        }
    }

    &Active {
        cursor: pointer;
        transition: background .1s linear;

        &:hover {
            background: var(--background-blue);
        }
    }
}
