.container {
  &__filter {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-bottom: 32px;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      gap: 18px;
    }

    & > div {
      display: flex;
      align-items: center;
      gap: 32px;

      @media (max-width: 767px) {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        width: 100%;
      }
    }

    label {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      border-radius: 8px;
      gap: 8px;
      background: var(--background-blue);
      white-space: nowrap;

      @media (max-width: 767px) {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }
}

