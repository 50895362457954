.searchInput {
  height:45px;
 @media(max-width: 650px) {
   width: 100%;
 }
  &__input {
    position: relative;
    height:45px;
    width: 100%;
    border-radius: 8px;
    padding: 8px 16px;
    display: flex;
    background: var(--background-blue) ;
    align-items: center;

    .icon {
      position: absolute;

      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .input {
      padding-left: 30px;
    }
  }
}
