.pageHeader {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-start;;
  justify-content: space-between;
  padding: 0 16px;
  z-index: 9;

  @media(max-width: 650px) {
    flex-direction: column;
    gap: 12px;
  }

  &__leftPart {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 45px;

    @media(max-width: 650px) {
      width:100%;
      justify-content: space-between;
    }

    @media(max-width: 420px) {
      flex-direction: column;
      margin-bottom: 20px;
      gap: 12px;
    }

    &__title {
      color: var(--color-black);
      font-size: 18px;
      font-weight: 600;
      line-height: 21.94px;
      font-family: 'Montserrat', sans-serif;
      white-space: nowrap;
    }

    &__button {
      padding: 4px 8px;
      border-radius: 8px;
      gap: 8px;
      background: var(--background-blue);
      white-space: nowrap;

      &__text {
        background: var(--linear-gradient-blue-red);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
      }
    }
  }

  &__wrapper {
    display: flex;
    width: 100%;

    &_start {
      justify-content: start;
    }

    &_end {
      justify-content: end;
    }
  }
}
