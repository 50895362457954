.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__not_found {
    text-align: center;
    padding: 20px;
    font-size: 16px;
  }

  &__products {
    min-height: 580px;
    max-width: 100%;
    overflow-x: auto;
    border: 1px dashed #898989;
    border-radius: 20px;
    padding: 20px 0;

    &__title {
      font-size: 18px;
      text-align: center;
    }

    &_table {
      @media(max-width: 900px) {
        width: 1000px;
      }
    }
  }

  .hoverItem{
    background: var(--linear-gradient-blue-red);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .underlined{
    margin: 10px 20px;
    border: 1px solid rgb(226, 238, 247);
  }

  .title {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  .logo {
    width: 60px;
    height: 40px;
    object-fit: contain;
    object-position: center;
    margin-right: 24px;
    background-color: #fff;
    border-radius: 4px;
  }
}
