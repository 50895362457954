.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
  padding-top: 90px;

  @media (max-width: 900px) {
    padding-top: 0;
  }

  &__footer{
    width: 100%;
    position: relative;
  }

  &__content {
    margin: 20px;

    @media (max-width: 767px) {
      margin: 0px;
    }

    &__user {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 16px;
      width: 100%;
      box-shadow: 0px 4px 36px 0px rgba(0, 0, 0, 0.07);
      border-radius: 12px;

      margin-bottom: 30px;
    }

    &__grid {
      margin: 20px 0;
      width: 100%;
      height: auto;
      display: grid;
      grid-template-columns: 260px 1fr;
      gap: 24px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        margin: 0px;
      }


      &__leftPart {
        display: flex;
        flex-direction: column;
      }
    }

    &__grid__rightPart {
      padding: 40px;
      gap: 24px;
      border: 1px solid rgb(241, 241, 241);
      width: 100%;
      height: auto;
      border-radius: 16px;

      @media (max-width: 767px) {
        padding: 20px;
      }
    }
  }
}
