@import "../../../styles/variables";

.header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  background: var(--color-background);
  z-index: 999;

  &__content {
    padding: 0 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 10px;

    &__leftPart {
      display: flex;
      justify-content: center;
      align-items: center;

      &__logo {
        img {
          max-width: 200px;
        }
      }
    }

    &__rightPart {
      width: 100%;
      padding: 10px;
      gap: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__links {
        gap: 24px;
        padding: 10px;
        width: 100%;
        display: flex;

        &__item {
          display: flex;
          align-items: center;
          padding: 8px 16px;
          gap: 8px;

          &__text {
            color: var(--color-second);
            font-weight: 600;
            &__active {
              color: red;
            }
          }
        }
      }
    }
  }
}
