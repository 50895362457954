.footer {
  width: 100%;
  background: rgb(242, 251, 254);
  position: fixed;
  z-index: 999999999999999999999999999;
  bottom: 0;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__items {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 20px 10px 20px;
  }
}