.container {
  width: 100%;
  height: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;

    form {
      max-width: 700px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &__inputs {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-direction: column;
    }
  }
}