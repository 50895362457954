.statusContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &__circle {
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    border-radius: 50%;
  }

  &__text {
    font-size: 14px;
  }
}
