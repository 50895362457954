.container {
  height: 100%;
  width: calc(100vw - 400px);
  overflow-x: auto;

  @media (max-width: 767px) {
    width: calc(100vw - 40px);
  }
}

.underlined {
  padding: 0 40px;
  border: 1px solid rgb(226, 238, 247);
}

.table {
  display: flex;
  flex-direction: column;
  min-width: min-content;
  width: 100%;

  &__header {
    color: var(--color-second);
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
  }
}
