.userProfile {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  border-radius: 32px;
  background: #FFFFFF;
  padding: 6px 10px;

  &::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: var(--linear-gradient-blue-red);
    border-radius: 32px;
    z-index: -1;
  }

  &__options {
    position: absolute;
    opacity: 0;
    border-radius: 12px;
    right: 5%;
    visibility: hidden;
    color: var(--background-main);
    background: var(--linear-gradient-blue-red);
    top: 125%;
    transition: 0.3s opacity, .2s top, 0.3s visibility;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;

    &__active {
      opacity: 1;
      background: var(--linear-gradient-blue-red);
      top: 115%;
      visibility: visible;
      border-radius: 12px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &__userAvatar {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      overflow: hidden;

      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__userName {
      color: var(--color-black);
      white-space: nowrap;
    }

    &__arrow {
      display: flex;
      align-items: center;
      transition: 0.3s transform;

      &__active {
        transform: scaleY(-1);
      }
    }
  }
}
