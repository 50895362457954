.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__products {
    min-height: 580px;
    max-width: 100%;
    overflow-x: auto;

    &_table {
      @media(max-width: 900px) {
        width: 800px;
      }
    }
  }

  .hoverItem{
    background: var(--linear-gradient-blue-red);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .underlined{
    margin: 10px 20px;
    border: 1px solid rgb(226, 238, 247);
  }

  .title {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
