.footerItem {
  height: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &_title {
    color: rgb(115, 115, 115);
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0%;
    text-align: left;

    &_active {
      background: linear-gradient(90.00deg, rgb(115, 73, 158),rgb(248, 69, 96));
      -webkit-background-clip:
              text;
      -webkit-text-fill-color:
              transparent;
      background-clip:
              text;
      text-fill-color:
              transparent;
    }
  }

}